import React from "react"
import SEO from "../components/seo";
import Layout from "../components/layout"
import Hero from "../components/hero"
import Info from "../components/info";
import AboutMore from "../components/about-more";
import Work from "../components/work";
const description  = 'Paper10 Tech is bunch of technology aristocrats, dedicated to bringing our customer’s visions to life using modern tools and style to bring a lasting experience.'
export default () => (
    <Layout>
        <SEO title="Digital Agency with a world class approach" description={description} keywords={[`PaperTen`, `Business`,`Solutions`, `Technology`, `Software`]} />
        <Hero />
        <Info />
        <AboutMore />
        <Work />
    </Layout>

    );
