import React from "react"

const Hero = () => {
  return (
    <>
      <section className="banner_part">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-6 col-md-6">
              <div className="banner_text">
                <div className="banner_text_iner text-center">
                  <h2>
                    Business <span>Solutions Bringing</span>{" "}
                  </h2>
                  <h3>Ideas Into Life</h3>
                  <a href="/about" className="btn_1">
                    learn more <i className="ti-angle-right"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-6">
              <div className="banner_bg">
                <img src="/img/banner_img.png" alt="banner" />
              </div>
            </div>
          </div>
        </div>
        <div className="hero-app-1 custom-animation">
          <img src="/img/animate_icon/icon_1.png" alt="" />
        </div>
        <div className="hero-app-5 custom-animation2">
          <img src="/img/animate_icon/icon_3.png" alt="" />
        </div>
        <div className="hero-app-7 custom-animation3">
          <img src="/img/animate_icon/icon_2.png" alt="" />
        </div>
        <div className="hero-app-8 custom-animation">
          <img src="/img/animate_icon/icon_4.png" alt="" />
        </div>
      </section>
    </>
  )
}

export default Hero
